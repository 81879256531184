<template>
    <div class="component-slide-in-title">
        <template v-if="!edit">
            <h5 v-if="editable"
                :title="$t('actions.click-to-rename')"
                class="d-inline-block m-0"
                data-bs-placement="bottom"
                data-bs-toggle="tooltip"
                @click="onClickRename">
                <strong>{{ mutableTitle }}</strong>
            </h5>
            <h5 v-else class="d-inline-block m-0">
                <strong>{{ mutableTitle }}</strong>
            </h5>
            <p v-if="mutableSubtitle" class="text-muted mb-0">{{ mutableSubtitle }}</p>
            <p class="m-0">
                <code class="text-muted">{{ uuid }}</code>
            </p>
        </template>

        <template v-else>
            <form v-if="edit" class="w-100" @submit.prevent="$emit('update', mutableTitle, mutableSubtitle)">
                <div class="d-flex flex-row">
                    <div class="desc flex-grow-1 me-1">
                        <input v-model="mutableTitle" class="form-control form-control-sm mb-1" type="text">
                        <input v-model="mutableSubtitle" class="form-control form-control-sm" type="text">
                    </div>
                    <div>
                        <div class=" btn-group-sm">
                            <button class="btn d-block w-100 text-start btn-sm btn-outline-success mb-1" type="submit">
                                <font-awesome-icon class="me-1" icon="check"/>
                                {{ $t('actions.rename') }}
                            </button>
                            <button class="btn d-block w-100 text-start btn-sm btn-outline-danger" type="button"
                                    @click="edit = false;">
                                <font-awesome-icon class="me-1" icon="times"/>
                                {{ $t('actions.cancel') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
export default {
    name: "ComponentSlideInTitle",
    data() {
        return {
            mutableTitle: this.title,
            mutableSubtitle: this.subtitle,
            edit: this.edit
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        uuid: {
            type: String,
            required: true
        }
    },
    methods: {
        onClickRename() {
            if (this.editable) {
                this.edit = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-slide-in-title {
    form {
        width: 100%;
        max-width: 60ch;
    }
}
</style>