<template>
    <div :style="{width, height}"
         class="bg-indigo rounded-circle d-flex justify-content-center align-items-center">
        <span class="text-white fs-2"><slot/></span>
    </div>
</template>

<script>
export default {
    name: "ComponentAvatar",
    props: {
        width: {
            type: String,
            required: false,
            default: '64px'
        },
        height: {
            type: String,
            required: false,
            default: '64px'
        }
    }
}
</script>

<style scoped>

</style>