export default function shouldOpenSlideIn(target) {
    if (Object.prototype.hasOwnProperty.call(target.dataset, 'bsToggle')
        && target.dataset.bsToggle === 'dropdown'
    ) {
        return false;
    }

    if (target.classList.contains('btn-checkbox') || target.classList.contains('dropdown-item')) {
        return false;
    }

    return true;
}