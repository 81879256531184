<template>
    <div :class="size"
         class="slide-in-container border shadow-lg bg-white p-4"
         :style="'z-index: ' + Number(99 + (2 * level))">
        <button class="btn btn-close position-absolute top-0 end-0 p-3" @click="$emit('close')"></button>
        <slot/>
    </div>
</template>
<script>
export default {
    name: "ComponentSlideIn",
    props: {
        size: {
            type: [String],
            required: false
        },
        level: {
            type: Number,
            required: false,
            default: 1
        }
    }

}
</script>
<style lang="scss" scoped>
.slide-in-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -650px;
    width: 650px;
    -webkit-transition: right .4s ease-in-out, width .4s;
    -moz-transition: right .4s ease-in-out, width .4s;
    -ms-transition: right .4s ease-in-out, width .4s;
    -o-transition: right .4s ease-in-out, width .4s;
    transition: right .4s ease-in-out, width .4s;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.lg {
        width: calc(100vw - 250px);
        right: calc(-250px - 100vw);
        -webkit-transition: right .6s ease-in-out, width .6s;
        -moz-transition: right .6s ease-in-out, width .6s;
        -ms-transition: right .6s ease-in-out, width .6s;
        -o-transition: right .6s ease-in-out, width .6s;
        transition: right .6s ease-in-out, width .6s;
    }


    &::-webkit-scrollbar {
        display: none;
    }

    &.active {
        right: 0;
    }
}
</style>
